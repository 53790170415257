<script lang="ts">
export enum displayMode {
  Fixed = 'fixed',
  Absolute = 'absolute',
}
</script>

<script setup lang="ts">
import { onMounted, ref, PropType, watch, nextTick } from 'vue';
import { useFloating, autoUpdate, offset, flip, shift } from '@floating-ui/vue';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  mode: {
    type: String as PropType<displayMode>,
    default: displayMode.Absolute,
  },
  customStyles: {
    type: String,
    default: null,
  },
  onClose: {
    type: Function as PropType<() => void>,
    default: null,
  },
  onOpen: {
    type: Function as PropType<() => void>,
    default: null,
  },
  target: {
    type: String,
    default: 'body'
  }
});

const containerRef = ref();
const isMounted = ref();
const dropdownWrapper = ref<HTMLElement>();
const dropdownContent = ref<HTMLElement>();
const dropdownButton = ref<HTMLElement>();
const dropdownOpen = ref(false);

const { floatingStyles } = useFloating(dropdownButton, dropdownContent, {
  whileElementsMounted: autoUpdate,
  placement: 'bottom',
  middleware: [offset(0), flip(), shift({ padding: 20 })],
});

const detectClickOutside = (e: MouseEvent) => {
  e.stopPropagation();
  if (dropdownWrapper.value == e.target) closeDropdown();
};

const handleDropdownOpen = () => {
  if (!props.disabled) {
    dropdownOpen.value = !dropdownOpen.value;
  }

  if (props.onOpen) nextTick(() => props.onOpen());
};

const closeDropdown = () => {
  dropdownOpen.value = false;
  if (props.onClose) props.onClose();
};

onMounted(() => {
  isMounted.value = true;
});

watch(dropdownContent, () => {
  if (dropdownContent.value) {
    document.addEventListener('click', detectClickOutside);
  } else {
    document.removeEventListener('click', detectClickOutside);
  }
});
</script>

<template>
  <div
    ref="containerRef"
    class="relative"
  >
    <div
      ref="dropdownButton"
      @click="handleDropdownOpen()"
    >
      <slot
        name="button"
        :is-open="dropdownOpen"
      />
    </div>
    <teleport
      v-if="isMounted"
      :to="props.target"
    >
      <transition
        enter-active-class="transition-all ease-out"
        leave-active-class="transition-all ease-in"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="dropdownOpen"
          ref="dropdownWrapper"
          class="fixed top-0 left-0 bottom-0 right-0 z-40"
          @click="detectClickOutside"
        >
          <div
            id="dropdownContent"
            ref="dropdownContent"
            :style="floatingStyles"
            class="z-50 flex flex-col overflow-hidden rounded-lg bg-dropdown shadow-dropdown text-white mr-10"
            :class="[customStyles, mode]"
            @touchstart.stop
            @touchmove.stop
            @touchend.stop
          >
            <slot :close="closeDropdown" />
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>
